export const monthSerb = [
  "januar",
  "februar",
  "mart",
  "april",
  "maj",
  "jun",
  "jul",
  "avgust",
  "septembar",
  "oktobar",
  "novembar",
  "decembar",
];
export const idsMonths = [
  [0, 31],
  [31, 59],
  [59, 90],
  [90, 120],
  [120, 151],
  [151, 181],
  [181, 212],
  [212, 243],
  [243, 273],
  [273, 304],
  [304, 334],
  [334, 365],
];
export const daysIsPost = [
  [0, 18],
  [8, 11],
  [8, 27],
];
export const manualDateEaster = [
  "4-19",
  "5-2",
  "4-24",
  "4-16", //23
  "5-5", //24
  "4-20", //25
  "4-12", //26
  "5-2",
  "4-16",
];
export const calendarYears = [
  {
    item_list: [
      {
        title: 2022,
        route: "/crkveni-kalendar/2022",
        zadusnice: [
          [1, 26],
          [5, 11],
          [9, 8],
          [10, 5],
        ],
      },
      {
        title: 2023,
        route: "/crkveni-kalendar/2023",
        zadusnice: [
          [1, 18],
          [5, 3],
          [9, 7],
          [10, 4],
        ],
      },
      {
        title: 2024,
        route: "/crkveni-kalendar/2024",
        zadusnice: [
          [2, 9],
          [5, 22],
          [9, 5],
          [10, 2],
        ],
      },
      {
        title: 2025,
        route: "/crkveni-kalendar/2025",
        zadusnice: [
          [1, 22],
          [5, 7],
          [9, 11],
          [10, 1],
        ],
      },
      {
        title: 2026,
        route: "/crkveni-kalendar/2026",
        zadusnice: [
          [1, 14],
          [4, 30],
          [9, 10],
          [10, 7],
        ],
      },
      {
        title: 2027,
        route: "/crkveni-kalendar/2027",
        zadusnice: [
          [2, 6],
          [5, 19],
          [9, 9],
          [10, 6],
        ],
      },
      {
        title: 2028,
        route: "/crkveni-kalendar/2028",
        zadusnice: [
          [1, 19],
          [5, 3],
          [9, 7],
          [10, 4],
        ],
      },
    ],
    zadusnice: ["zimske", "letnje", "miholjske", "jesenje (mitrovske)"],
  },
];
export const inCalendarArr = [
  "div-gpt-ad-1724672473185-0",
  "div-gpt-ad-1724680335213-0",
  "div-gpt-ad-1724680376368-0",
  "div-gpt-ad-1724680398271-0",
  "div-gpt-ad-1724680417311-0",
];
export const redDaysId = [
  7, 8, 9, 14, 19, 20, 27, 46, 153, 154, 163, 164, 165, 188, 193, 214, 231, 240,
  254, 264, 270, 300, 304, 312, 325, 338, 353,
];
export const daysIsNotPost = [
  [0, 7],
  [0, 19],
  [1, 16],
  [1, 18],
];
export const sedmicaBludnogSina = [
  [1, 14],
  [1, 6],
  [1, 26],
  [1, 10],
  [1, 2],
  [1, 22],
  [1, 7],
];
export const easterDays = [
  "Veliki četvrtak (Veliko bdenije)",
  "Veliki petak",
  "Velika subota",
  "V a s k r s – Vaskrsenje Gospoda Isusa Hrista",
  "Vaskrsni  ponedeljak",
  "Vaskrsni utorak",
];
